/* For devices with narrow screens, phones, tablets, etc. */
/*  * 1625px and above huge screens * */
/*  * 600px and below-common things for 480px, 600px, and below .. IE 9 ignored this if it had 'only screen' in the query   * */
/*  * iPad in landscape mode * */
/*  * iPad in portrait mode * */
/*  * iPhone in landscape mode * */
/*  * iPhone in portrait mode * */

/*  *  1625px and above huge screens * */
@primary-color: #00618c;
// navegacao
@nav: #fff;
@nav-link: contrast(@nav, #000, #fff);


/*  1625px and above huge screens */
@media screen and (min-width : 1625px)  {
    body { width: 100%;}
    
    /* for readability making the width of the site only got to 100em */ 
    #visual-portal-wrapper {
        margin: 0px auto !important;
        display: block;
        float: none;
    }
    
    // WA10
    .autocomplete-suggestions {
        font-size: 16px;
    }
    
}
/*  *  END 1625px and above huge screens * */




@media screen and (max-width : 800px) {
    
    #visual-portal-wrapper {
        width: 100% !important;
        margin: 0px auto !important;
        display: block;
        float: none;
    }
    
    #portal-searchbox {
        margin-right: 10%;
    }
    
    .top-bar.expanded {
        #topbar-search {
            #portal-searchbox {
                margin: 0 1rem 1rem 0;
            }
        }
    }

    header > div {
	    width: 98%;
    }

    #logo { 
	 	float: right;
        margin: 0.5rem 0 0 0;
        width: 10%;
	}

}

/*  600px and below-common things for 480px, 600px, and below .. IE 9 ignored this if it had 'only screen' in the query */
// burgar menu break point is 600px
@media screen and (max-width: 600px) {
    /* reset the min width to be 320px */
    body { min-width: 320px !important;}
    #visual-portal-wrapper {
        width: 100% !important;
    }
    
   #portal-logo {
       margin-left: 30px;
       display: inline-block;
       clear: both;
       margin: 0px;}
       
   #portal-logo img { 
       height: auto;
       margin: 10px;
       }
       
    /* make width 100% instead of the stock 97.75% */
    div.width-full {
        width: 100% !important;
        }
        
    /* start position-0 at absolute left */
    div.position-0 {
        margin-left: -100%;
        }

    /* get rid of whatever width is set on visual-portal-wrapper so it expands whole device width */
    #visual-portal-wrapper {
        border: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        }
        
    /* move columns to below main content */    
    #portal-column-two, #portal-column-one {
        clear: both;
        margin-left: -99% !important;
        width: 100% !important;
        margin:1em 0px;
        width: 95% !important;
        } 
        
    /* spread out the content */
    div#portal-column-content {
        width: 94%;
        margin-left: -99%;
        }
        
    dl#portal-personaltools {
        margin-right: 34px;
        float: right;
        }
       
    #portal-searchbox {
        margin-right: 10%;
    }
    
    .top-bar.expanded {
        #topbar-search {
            #portal-searchbox {
                margin: 0 1rem 1rem 0;
            }
        }
    }
    
    #searchGadget {
        width: 81%;
        }
    .searchform #searchString { width: 211px; }
    
    #portal-personaltools {
       font-size: 70%;
       }

   /* global navigation needs to reconfigure itself to be vertical */
  #portal-globalnav {
        margin-left: 0px !important;
        }
                
    ul#portal-globalnav {
        padding: 0px;
        background-color: white !important;
        background: white !important;
        width: 100% !important;
        }
        
    ul#portal-globalnav:before { 
        display: block;
        font-size: 2em; 
        }
         
    #portal-globalnav {
        height: auto;
        clear: both;
        padding: 0px;
        margin: 7px auto;
        width: 100% !important;
        }
        
    #portal-globalnav li {
        display: list-item !important;
        margin-top: 5px;
        border-top: 1px solid #E7E7E7;
        border-bottom: 1px solid #E7E7E7;
        background-color: white;
    }
    
    #portal-globalnav {
        li {
            a {
                width: 90px;
                background: lighten(@primary-color, 10) url(++theme++ftstheme.waop/static/tab90.png) no-repeat 0 0;
            }
        }
        
        // too long text, need smaller
        li#portaltab-stockgame {
            a {
                height: 30px;
                font-size: 9px;
            }
        }
    }

   .nav-menu .nav-primary.expanded #portal-globalnav li, .nav-menu .nav-primary #portal-globalnav li.navigation-pulldown { display: list-item !important; }
   .nav-menu .nav-primary #portal-globalnav li { display: none !important; }
   #portal-globalnav li.navigation-pulldown {
       padding: 0.5em 1em 2em;
       background-color: #E3E3E3;
       }
       

/* luke w says breadcrumbs are not found in mobile designs */    
    #portal-breadcrumbs {
        display: none; 
        }
        
 /* make the content font-size bigger */
   #content {
       font-size: 0.9em;
       padding: 1em;
       } 
   /*#content-core { width: 98%;}*/
   #content h1 { font-size: 1.4em;}
   #content h2 { font-size: 1.2em;}
   div#footer {
       width: 99%;
       }   
    .LSBox input.searchButton {
        font-size: 2em;
    }
    
    #searchGadget {
        font-size: 2em;
        padding-left: 10px;
    }
    
    input.searchPage {
        font-size: 1em !important;
        }

    
    #LSResult {
        position: relative;
        right: inherit;
        float: left;
        margin: 0;
    }
    
    div#portal-columns { width: 99%; }
    #visual-portal-wrapper { width: 99% !important; }


    // new theme
	#main {
		margin: 3rem 0 0 0;
        padding: 0.5rem;
	}
	#logo { 
	 	float: right;
        margin: 0.5rem 0 0 0;
        width: 20%;
	}
	#logo a img {
		margin-top: 5px;
        width: 80%;
	}
    #what-is-waiic { 
	 	float: right;
        margin-right: 0 2rem 0 0;  // left of logo (width) 
	}
    .hot-info {
        img {
            width: 24px;
        }
    }
	#portal-breadcrumbs {
		display:none;
	}
	#main-header {
		height:45px;
	}
    
	#btnav {
		background:transparent url(img/btmenu.jpg) no-repeat 0 0;
		width: 153px;
		height: 40px;
		display: block;
		top: 0;
		position: absolute;
	}
	#btnav ul {
		display:none;
        width: 10em;
	}
	div:hover#btnav ul {
		padding-top:10px;
		display:block;
		top:40px;
		position:absolute;
		background: @nav;
	}
	div:hover#btnav ul li a {
		padding:10px;
	}
	#btnav i {
		display: block;
		font-size: 2em;
		padding-top:10px;
	}
    
	header > div {
		width:93%;
	}
	#main-content, #column-one, #column-two {
	    display: block;
	    float: none;
	}

    nav {
        ul {
            li {                
                padding-bottom: 0.4em;
                border-bottom: 1px solid black;
                float: none;
                display: block;
            }
        }
    }

    footer {
        #portal-siteactions {
            width: 100%;
        }
    }

    
} /*  * END 600px and below  * */


@media screen and (max-width: 486px) {
    #portal-searchbox {
        margin-right: 10%;
    }
    
    .top-bar.expanded {
        #topbar-search {
            #portal-searchbox {
                margin: 0 1rem 1rem 0;
            }
        }
    }
	#logo { 
	 	float: right;
        margin: 0.5rem 0 0 0;
        width: 20%;
	}
	#logo a img {
		margin-top: 5px;
        width: 80%;
	}
    
    nav {
        ul {
            li {
                padding-bottom: 0.4em;
                border-bottom: 1px solid black;
                float: none;
                display: block;
            }
        }
    }
}


/*  * iPad in portrait mode * */
@media screen and (orientation:portrait) and  (max-width: 768px) {

    #main {
        width: 100%;
        margin: 6em 0 1em 0;
        padding: 0 1em;
    }
    
   #visual-portal-wrapper {
 		width: 100% !important;
 		margin: 0 !important;
	}
	#portal-columns {
       font-size: 1.7em;
       }
    #portal-logo a#logo-site { padding-top: 2%;}

    #logo { 
	 	float: right;
        margin: 0.5rem 1.2rem 0 0;
        width: 15%;
	}

    #btnav {
        ul {
            margin: 0 1em;
        }
    }


} /*  * END Pad in portrait mode * */

/*  *iPad in landscape mode * */
@media screen and (orientation:landscape) and (max-width: 1024px) {
    body {
        font: 1em!important;
    } 
    #main {
        width: 100%;
        margin: 6em 0 1em 0;
        padding: 0 1em;
    }
    #visual-portal-wrapper {
 		width: 100% !important;
 		margin: 0 !important;
 	}
    #portal-columns {
       font-size: 1.7em;
       }
     #portal-logo a#logo-site { padding-top: 2%;}

    #logo { 
	 	float: right;
        margin: 0.5rem 0 0 0;
        width: 10%;
	}
    #btnav {
        ul {
            margin: 0 1em;
        }
    }

}


/*  iPhone 4 */
@media screen and (orientation:landscape) and (max-device-width: 480px),
screen and (orientation:portrait) and (max-device-width: 320px) {
    
   #portal-logo {
       clear: both !important;
       margin: 0;
       display: inline-block;
       width: 80% !important;
   }
   
   #portal-logo img { height: 40px;}  
    
   #portal-globalnav { margin-left: 0px !important; }
				
   ul#portal-globalnav {
		padding: 0px;
		background-color: transparent;
		width: 100% !important;
		}
		
	 ul#portal-globalnav:before { 
		display: block;
		margin-top: 6px;
		font-size: 2em; 
		}

    #portal-globalnav {
        li {
            a {
                width: 90px;
                background: lighten(@primary-color, 10) url(++theme++ftstheme.waop/static/tab90.png) no-repeat 0 0;
            }
        }
        
        // too long text, need smaller
        li#portaltab-stockgame {
            a {
                height: 30px;
                font-size: 9px;
            }
        }
    }
       
    .field input[type="text"][size] {
        width: 10em;
        } 
 
    div#content-core form#searchform.searchPage fieldset#search-field { 
        width: 100% !important; 
        padding: 0px;
        }
    fieldset#search-field input.searchPage:first-child {
        width: 50% !important;
        }
    input.searchPage.searchButton {
        width: 45% !important;
        margin-left: 2px;
        font-size: 0.05em;
    }

    // wa10
    table#wa10main {
        
        font-size: 80%;
        
        th, td {
            padding: 0.1rem 0.2rem;
            line-height: 0.8rem;
        }
            
    }
    
    #tickerchooser {
        width: auto;
    }
    
    // ewaiic theme
    header > div {
	    width: 98%;
    }

	div.cell {
		float: none;
		width: 100% !important;
		margin-left: -100% !important;
	}
	footer {
		padding-left: 2em;
		padding-right: 2em;
	}
    #main {
	    padding: 0;
    }

    #logo { 
	 	float: right;
        margin: 0.5rem 0 0 0;
        width: 20%;
	}


} /*  * END iPhone * */

/*  iPhone 7+ */
@media only screen 
and (min-device-width : 414px) 
and (max-device-width : 736px) {
    
    #portal-globalnav {
        li {
            a {
                width: 90px;
                background: lighten(@primary-color, 10) url(++theme++ftstheme.waop/static/tab90.png) no-repeat 0 0;
            }
        }
        
        // too long text, need smaller
        li#portaltab-stockgame {
            a {
                height: 30px;
                font-size: 9px;
            }
        }
    }

    #logo { 
	 	float: right;
        margin: 0.5rem 0 0 0;
        width: 18%;
	}

    #btnav {
        font-size: 20px;

        ul {
            width: 24em;

            li {
                font-size: 80%;
            }
        }

        i {
            font-size: 1.5em;
        }
    }
}
@media only screen and (orientation:landscape) and (max-device-width : 736px) {
    #logo { 
	 	float: right;
        margin: 0.2rem 1.2rem 0 0;
        width: 10%;
	}
}
